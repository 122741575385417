import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labels-forms',
  templateUrl: './labels-forms.component.html',
  styleUrls: ['./labels-forms.component.css']
})
export class LabelsFormsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
