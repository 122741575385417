import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import * as AWS from "aws-sdk";
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from 'src/app/services/auth.service';
declare var $: any;
import { Router } from '@angular/router';
import { navigation_screen } from 'src/app/CardiologyComponentV10/interfaces/interface-system';
import { fileDataConnection } from 'src/app/CardiologyComponentV10/models/catalogsModel';
import { FileSystemDataService } from 'src/app/CardiologyComponentV10/services/file-system-data.service';
import { UserProfileService } from 'src/app/CardiologyComponentV10/services/user-profile.service';
import { userModelV1 } from 'src/app/model/userModel';
import { DomSanitizer } from '@angular/platform-browser';
//import { system_interface } from 'src/app/AIONSystemsComponentsV1_0_0/model/interface-system';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers: [FileSystemDataService, UserProfileService]
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  //////////////DATOS DE USUARIO
  username:               string;
  branch_office_name:     string;


  constructor(
    private routes: Router,
    private connection_data:FileSystemDataService,
    private user_service:UserProfileService,
    private modalService: NgbModal,
    private service: AuthService,
    private sanitizer:DomSanitizer) {}

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];
  logout(e) {
    e.stopPropagation();
    e.preventDefault();
    this.service.logout().subscribe();
    return false;
  }
  user_name_val:string;
  ngOnInit(){
    this.user_name_val= localStorage.getItem("username_enc");
    this.username = localStorage.getItem("user_name_prof");
    //localStorage.getItem('user');
    this.getFileSystemData(1);
    if (this.username != null || this.username == ""){
      if (this.username.length > 23 ){
        this.username = this.username.substring(0, 23) + '...';
      }else{
        this.username;
      }
    }


    ///INIT COMPONENTs
      this.user_photo = "assets/images/users/1.jpg"
      this.options_value.logout_label = "Salir";
      this.options_value.profile_label = "Perfil de Usuario";
      //this.getUserByID(Number(localStorage.getItem("user_id")));
  }
  userProfile(){
    this.routes.navigate(['/user-profile']);
  }
  ngAfterViewInit() {

  }
  user_data={} as userModelV1;
  getUserByID(user_id:number){
    this.user_service.getUserDataByID(user_id).subscribe((res:any)=>{
      if (res.api_code==200){
        this.user_data=res.data;
        //console.log(this.user_data);
       // this.getFileSystemData(1);
        this.getSignedUrlAWS(this.user_data.photo, this.user_data.photo, 'download_object');
      }
    });
  }
  connection_info:fileDataConnection;
  getFileSystemData(id_connection:number){
    //////console.log("getFileSystemData");
    this.connection_data.getConnectioDataForFS(id_connection).subscribe((res:any)=>{
      if(res.api_code==200){
        this.connection_info=res.data;
        ////console.log(this.connection_info);
        this.getUserByID(Number(localStorage.getItem("user_id")));
      }
    });
  }
  user_profile_img:any;
  nombre_archivo:string="Subir foto...";
  file_object_:any;
  getSignedUrlAWS(original_name, pathFile, action): string {
    const aws = require("aws-sdk");

    const endpoint = new AWS.Endpoint(this.connection_info.server_ip_address);
    let s3 = new AWS.S3({
      accessKeyId: this.connection_info.username,
      secretAccessKey: this.connection_info.password,
      endpoint: endpoint
    });
    var params = {
      Bucket: this.connection_info.directorio_remoto,
    };

    var uploadParams = {Bucket: params.Bucket, Key: '', Body: ''};
    var file = this.nombre_archivo;//this.upload_data.file_name;
    var fileStream =  this.file_object_;//this.upload_data.file;//this.reader;//fs.createReadStream(file);
    uploadParams.Body = fileStream;
    var path = require('path');
    uploadParams.Key = pathFile;
    if (action=="read_object"){
      s3.listObjects(params, function(err, data) {
        if (err) {
          ////console.log("Error:", err);
        } else {
          console.log("Success:", data);
        }
      });
    }else if (action=="create_object"){

      let this_=this;

    }else if (action=="download_object"){
      const params = {
        Bucket: "aion-cardiology-system",
        Key: pathFile
      };
      let this_=this;
      s3.getObject(params, function (err, data) {
        if (err) {
        } if (data) {

          var enc = new TextDecoder("utf-8");
          const data_ = new Uint8Array(data.Body as ArrayBuffer);

          this.blob = new Blob([data_]);
          var urlCreator = window.URL;

          //if (window.webkitURL !== undefined) {
           // ////console.log(window.webkitURL);
            urlCreator = window.URL;// || window.webkitURL;
        //  }

          var downloadURL = urlCreator.createObjectURL(this.blob);
         // var downloadURL = window.URL.createObjectURL(data.Body);
          //var link = document.createElement('a');


          this_.user_photo = this_.sanitizer.bypassSecurityTrustUrl(downloadURL);
         // link.href = downloadURL;
          //this_.user_profile_img ="data:image/jpeg;base64," +btoa(data_.toString());
          ////console.log(this_.user_profile_img);
         // link.download = original_name;
         // link.click();
        }
      });

    }else if(action=="delete_object"){
      const params = {
        Bucket: "aion-cardiology-system",
        Key: pathFile
      };
      let this_=this;
      s3.deleteObject(params, function (err, data) {
        if (err) {
          ////console.log("File NOT deleted....... "+params.Key);
        } if (data) {
          ////console.log("File deleted....... "+params.Key);
         ////console.log(data);
        }
    });
    }
   /* const url = s3.getSignedUrl("getObject", {
      Bucket: bucket,
      Key: pathFile,
      Expires: 8000,
      //ResponseContentType: "application/pdf",
    });
*/


    return "url";
  }
  /*CUSTOM COMPONENTS
  AUTHOR: Adán Mateos Hernández
  DESC: Get component to set logos and images from data base.
  */
 options_value = {} as navigation_screen;
 user_photo:any;

 /*END CUSTOM COMPONENT */
}
