import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-column-buttons',
  templateUrl: './custom-column-buttons.component.html',
  styleUrls: ['./custom-column-buttons.component.scss']
})
export class CustomColumnButtonsComponent implements OnInit {

  constructor() { }
  @Input() value: {} ;
  ngOnInit() {
    ////console.log("value ");
  //  //console.log(this.value);
  }

}
