import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DragDropModule, DragDropRegistry } from "@angular/cdk/drag-drop";
import {
  ScrollingModule,
  ViewportRuler,
  ScrollDispatcher,
} from "@angular/cdk/scrolling";
import { PlatformModule, Platform } from "@angular/cdk/platform";
import { SpinnerComponent } from "src/app/shared/spinner.component";
import { EmaSpinnerComponent } from "../AIONSystemsComponentsV1_0_0/ema-spinner/ema-spinner.component";
import { EmaToolbarComponent } from "../AIONSystemsComponentsV1_0_0/ema-toolbar/ema-toolbar.component";
import { EmaFormsComponent } from "../AIONSystemsComponentsV1_0_0/ema-forms/ema-forms.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CustomColumnButtonsComponent } from "../CardiologyComponentV10/tables/customColumns/custom-column-buttons/custom-column-buttons.component";
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';

@NgModule({
  declarations: [
    SpinnerComponent,
    EmaToolbarComponent,
    EmaFormsComponent,
    EmaSpinnerComponent,
    CustomColumnButtonsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ScrollingModule,
    PlatformModule,
    NgMultiSelectDropDownModule

  ],
  exports: [
    SpinnerComponent,
    EmaSpinnerComponent,
    EmaToolbarComponent,
    EmaFormsComponent,
    CustomColumnButtonsComponent
  ],
  providers: [ViewportRuler, Platform, DragDropRegistry, ScrollDispatcher],
//  entryComponents: [CustomColumnButtonsComponent]
})
export class SharedModuleModule {}
