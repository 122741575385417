import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { RouterModule, Router } from '@angular/router';
@Component({
  selector: 'app-acceso-denegado',
  templateUrl: './acceso-denegado.component.html',
  styleUrls: ['./acceso-denegado.component.css']
})
export class AccesoDenegadoComponent implements OnInit {
  defaultURL = '';
  constructor(private router: Router, private location: Location) { }

  ngOnInit() {
    let menuTemp = JSON.parse(sessionStorage.getItem('menu'));
    this.defaultURL = menuTemp.defaultURL;
  }

}
