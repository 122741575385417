import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ema-page-header',
  templateUrl: './ema-page-header.component.html',
  styleUrls: ['./ema-page-header.component.css']
})
export class EmaPageHeaderComponent implements OnInit {
  action_:string;
  page_description:string;
  patient_name:string;
  patient_id:string;
  constructor() { }

  ngOnInit() {
  }

}
