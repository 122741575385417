import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ema-tables',
  templateUrl: './ema-tables.component.html',
  styleUrls: ['./ema-tables.component.css']
})
export class EmaTablesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
