import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import 'rxjs/add/operator/map'

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private httpClient: HttpClient) { }
  getUserDataByID(user_id) {
    ////console.log("--- En servicio ---");
    return this.httpClient
    .get(environment.api.user_profile_data_get+"/"+user_id
    ).map(data =>
    data);
  }

  saveUserData(user_data) {
    return this.httpClient
    .post(environment.api.user_profile_data_save, user_data
    ).map(data =>
    data);
  }
}
