import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class FileSystemDataService {

  constructor(private httpClient: HttpClient) { }

  getConnectioDataForFS(connection_id) {
    return this.httpClient
    .get(environment.api.get_file_system_data+"/"+connection_id
    ).map(data =>
    data);
  }
}
