import * as AWS from 'aws-sdk';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { PagesOptionsService } from 'src/app/CardiologyComponentV10/services/pages-options.service';

export class emaUtils {
  encPassword= "mateos_$";


  validateEmail(emailField:string){

    // Define our regular expression.
    let validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    // Using test we can check if the text match the pattern
    if( validEmail.test(emailField) ){
      return true;
    }else{
      return false;
    }
  }
  operationTime(start_time:string, end_time:string){
    let days = new Date(end_time).getTime() - new Date(start_time).getTime();
    let min = Math.floor((days/1000/60) << 0);
    return min;
  }
  operationTimeDays(date_:Date, days_:number){
    var res = new Date(date_);
    res.setDate(res.getDate() + days_);
    return res;
  }
  toUpperCaseFirst(text_:string){
    //console.log("--------------text_");
    //console.log(text_);
    const words = text_.split(" ");
    let resp="";
    //console.log(words);
    for (let i=0; i < words.length; i++){
      if (words[i][0]!=undefined){
        resp += (words[i][0]).toUpperCase() + words[i].substr(1).toLowerCase()+" ";
      }

    }
    return resp;
  }
  getUserData(option:string){
    if (option=="id-and-name"){

    }else if (option=="user-id"){
      return localStorage.getItem("user_id");
    }else if (option=="name"){

    }else if (option=="role-id"){
      return localStorage.getItem("role_id");
    }
  }
  date_ = new Date();
  getDateAndTime(option:string){
    let date_mng= new DatePipe('en-US');
     let today_ =  new Date();
   //console.log("today_ " + today_);


    if (option=="date"){
      let today_str = date_mng.transform(today_, 'yyyy-MM-dd');
     // console.log(today_str);
      //let date_1 = new Date(today_str);
      return today_str+"";//date_1.getFullYear().toString()+ "-"+ this.padWithZeroes(date_1.getMonth().toString(), 2)+"-"+this.padWithZeroes(date_1.getDay().toString(), 2);
    }else if (option=="time"){
     // let today_str = date_mng.transform(today_, 'yyyy-MM-dd');
     // this.date_ = new Date();
      return this.padWithZeroes((this.date_.getHours()), 2)+":"+this.padWithZeroes(this.date_.getMinutes(), 2)+":"+this.padWithZeroes(this.date_.getSeconds(),2);
    }else if (option=="year"){

      return this.date_.getFullYear().toString();
    }
  }
  getDateAndTime2(option: string): string {
    if (option === "date") {
      return this.date_.getFullYear().toString()
        + "-" + this.padWithZeroes((this.date_.getMonth() + 1).toString(), 2)
        + "-" + this.padWithZeroes(this.date_.getDate().toString(), 2);
    } else if (option === "time") {
      return this.padWithZeroes(this.date_.getHours().toString(), 2)
        + ":" + this.padWithZeroes(this.date_.getMinutes().toString(), 2)
        + ":" + this.padWithZeroes(this.date_.getSeconds().toString(), 2);
    } else if (option === "year") {
      return this.date_.getFullYear().toString();
    }
    return "";  // Si el valor de option no es válido, devolvemos una cadena vacía (opcional)
  }
  padWithZeroes(number, length) {

    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }

    return my_string;

  }
  saveJSONInLocalStorage(name: string, value: any){
    localStorage.setItem(name, JSON.stringify(value));
  }

  getJSONFromLocalStorage(name){
    return JSON.parse(localStorage.getItem(name));
  }
  deleteJSONInLocalStorage(name){
    localStorage.removeItem(name);
  }
  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
    }
  ageCaltulator(birthdate) {
    var today = new Date();
    var birthdate_ = new Date(birthdate);
    var age_ = today.getFullYear() - birthdate_.getFullYear();
    var m = today.getMonth() - birthdate_.getMonth();
   // //console.log(age_ + " m " + m);
    if (m < 0 || (m === 0 && today.getDate() < birthdate_.getDate())) {
      age_--;
    }

    return age_;
}
  stringMatching(text: string,  patern:string){
    var kmp = require('kmp');

   // //console.log(kmp(('CV ADAN MATEOS HERNANDEZ').toLowerCase(), ('').toLowerCase())); // 13
  }
  validityDateValidation(start_date, end_date, validity_type){
     let date_mng= new DatePipe('es-MX');
     let today_ =  new Date();
   ////console.log("start_date " + start_date);

    let today_str = date_mng.transform(today_, 'yyyy-MM-dd');
    let sater_date_ =  date_mng.transform(start_date, 'yyyy-MM-dd');
    let end_date_ =  date_mng.transform(end_date, 'yyyy-MM-dd');

    let day_end_ = new Date(end_date).getTime();
    let days_start = new Date(start_date).getDay() - new Date().getDay();
    let days_end =   new Date().getDay() - new Date(end_date).getDay();
    ////////////////////

    var millisecondsPerDay = 1000 * 60 * 60 * 24;
    var millisBetween = day_end_ - new Date(today_str).getTime();
    var days = millisBetween / millisecondsPerDay;
    //////////////////////
    ////console.log("end_date " + end_date+ " - today_ " + today_str + " dt1[2] >>>>>>>>> days = "  + days);
    if (validity_type == 'start'){
        return  days;
    }else if (validity_type == 'end'){
      return days;
    }
  }
   getSignedUrlAWS(bucket, pathFile) : string{
    const aws = require('aws-sdk');
    let s3 = new AWS.S3({
      accessKeyId: 'AKIAZI5VJPNY4HKDNAEK',
      secretAccessKey: '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',
      region:'us-east-2'
    });
    const url = s3.getSignedUrl('getObject', {
      Bucket: bucket,
      Key: pathFile,
      Expires: 8000,
      ResponseContentType: 'application/pdf'
  })
    return url;
  }




  async documentsDownloading(folder_name, filesList ){

    const zip = new JSZip();
    const name = folder_name + '.zip';
    let files_: any[];

    for (let i=0; i < filesList.length; i++){

     let file_url = this.getSignedUrlAWS(filesList[i].bucket_name, filesList[i].key);
      ////console.log("file to download: " + this.documentsGeneral[i].filename_path);
      const element = filesList[i].original_file_name;
      const file_data: any = await fetch(file_url).then(res => res.blob());

      var binaryData = [];
      binaryData.push(file_data);
      const url:any = new Blob(binaryData, {type: "application/pdf"});
      zip.file(folder_name+"/"+filesList[i].file_track_name +"/"+ element, url);
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });


 //   let filename = original_file_name;
    //return dataURL;
    /*
    var binaryData = [];
    binaryData.push(data);
    window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
    */
   /* if(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1){
      window.open(file_url);
    }else{
      let blob = dataURL;
      /*var binaryData = [];
      binaryData.push(dataURL);
      let url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      //document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);*/

    //}
  }
  cleanCache(){
    ////console.log("User is logged - " + localStorage.getItem("'user"));
    localStorage.setItem("session_active", "inactive");

    localStorage.removeItem('username') ;
    localStorage.removeItem('passwor') ;

    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('menu');
    localStorage.removeItem('customer');
    localStorage.removeItem('customerBranch');
    localStorage.removeItem('user');
    localStorage.removeItem('branch_office_name');
    localStorage.removeItem('menu');
    localStorage.removeItem('submenu');
    localStorage.removeItem('esEmpleado');
    localStorage.removeItem('company1_id');
    localStorage.removeItem('company_branch_id');
    localStorage.removeItem('customerBranchId');
    //Se elimina menu
    localStorage.removeItem('titleMenu');
    localStorage.clear();
    sessionStorage.clear();
  }
/*  convertText(conversion:string, plainText:string, encryptText: string) {
    if (conversion == "encrypt") {
      return  CryptoJS.AES.encrypt(plainText.trim(), this.encPassword.trim()).toString();
    }
    else {
      return  CryptoJS.AES.decrypt(encryptText.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8);
    }
  }*/


  //RFC
  rfcValido(rfc: string, aceptarGenerico: boolean = true): string | false {
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let validado = rfc.match(re);

    if (!validado)  //Coincide con el formato general del regex?
        return false;

    const digitoVerificador = validado.pop(),
          rfcSinDigito = validado.slice(1).join(''),
          len = rfcSinDigito.length,
          diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
          indice = len + 1;
    let suma,
        digitoEsperado;

    if (len == 12) suma = 0
    else suma = 481; //Ajuste para persona moral

    for(var i=0; i<len; i++)
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - suma % 11;
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";

    if ((digitoVerificador != digitoEsperado)
     && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
        return false;
    else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
        return false;
    return rfcSinDigito + digitoVerificador;
  }

  //curp
  curpValida(curp: string): boolean {
    const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = curp.match(re);

    if (!validado)  //Coincide con el formato general?
    	return false;

    function digitoVerificador(curp17: string) {
        const diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        let lngSuma = 0.0,
            lngDigito = 0.0;
        for(let i=0; i<17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10) return 0;
        return lngDigito;
    }

    if (validado[2] != digitoVerificador(validado[1]).toString())
    	return false;

    return true; //Validado
  }
}
