// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//host:"http://adec-authorization.us-east-2.elasticbeanstalk.com"
//host:"http://devemasyst-env.eba-4v2abrbd.us-west-2.elasticbeanstalk.com/"
export const host_service={
  host:"https://a76cd9r6xf.execute-api.us-east-2.amazonaws.com/emaCardiology"//"https://3.144.201.63:8081"//http://ec2-18-118-158-79.us-east-2.compute.amazonaws.com:8081"
 // host:"http://localhost:8081"
}
export const environment = {
  production: false,
  ambiente: 'PRD',
  descrpcion_ambiente:'',
  api: {
    login: host_service.host + '/authenticate',
    user_profile_name_get:host_service.host+'/security/user-by-name',
    logout: host_service.host + '/log-out',
    refreshToken: host_service.host + '/refresh-token',
    menu: host_service.host + '/ema/cardiology/menu',
    patients_all:host_service.host + '/ema/cardiology/patients-all',
    patients_text_search:host_service.host + '/ema/cardiology/patients-search',
    patients_name:host_service.host + '/ema/cardiology/patients-name',
    patients_name_appat:host_service.host + '/ema/cardiology/patients-nameappat',
    patients_name_appat_apmat:host_service.host + '/ema/cardiology/patients-name-appat-apmat',
    patients_name_appat_apmat_fechanac:host_service.host + '/ema/cardiology/patients-name-appat-apmat-fechanac',
    patients_name_appat_apmat_fechanac_rfc:host_service.host + '/ema/cardiology/patients-name-appat-apmat-fechanac-rfc',
    patients_duplicates:host_service.host + '/ema/cardiology/patients-duplicates',

    patients_number:host_service.host + '/ema/cardiology/patients-number',
    patients_number_email:host_service.host + '/ema/cardiology/patients-number-email',
    patient_by_id:host_service.host + '/ema/cardiology/patients',
    patient_has_contact:host_service.host + '/ema/cardiology/contact',
    patient_has_contact_by_id:host_service.host + '/ema/cardiology/contacts',
    patient_has_identidades:host_service.host + '/ema/cardiology/patient-identidades',
    patient_has_identidades_by_id:host_service.host + '/ema/cardiology/patient-identidades',
    events_by_patient_id:host_service.host + '/ema/cardiology/events',
    event_by_id:host_service.host + '/ema/cardiology/events',
    event_by_event_id:host_service.host + '/ema/cardiology/events/by-event-id',
    catalog_pathological_by_catalog_type_id:host_service.host + '/ema/cardiology/catalog-pathological',
    catalog_by_catalog_type_id:host_service.host + '/ema/cardiology/catalog',
    catalog_all:host_service.host + '/ema/cardiology/catalogs-all',
    save_catalog:host_service.host + '/ema/cardiology/catalog',
    pathological_elements_by_patient_id:host_service.host + '/ema/cardiology/pathological-element',
    new_pathological_elements:host_service.host + '/ema/cardiology/pathological-element',
    delete_pathological_elements:host_service.host + '/ema/cardiology/pathological-element',
    get_pathological_elements:host_service.host + '/ema/cardiology/pathological-element/by-event',
    delete_no_pathological_elements:host_service.host + '/ema/cardiology/no-pathological-element',
    no_pathological_elements_by_patient_id:host_service.host + '/ema/cardiology/no-pathological-element',
    no_pathological_elements_by_patient_id_and_event_id:host_service.host + '/ema/cardiology/no-pathological-element/by-event',
    new_no_pathological_elements:host_service.host + '/ema/cardiology/no-pathological-element',
    new_anthropometric_data:host_service.host + '/ema/cardiology/anthropometric-data',
    get_anthropometric_data_by_id:host_service.host + '/ema/cardiology/anthropometric-data',
    get_anthropometric_data_by_patient_id:host_service.host + '/ema/cardiology/anthropometric-data',
    general_data_new:host_service.host + '/ema/cardiology/general_data',
    general_data_by_event_id:host_service.host + '/ema/cardiology/general_data',
    specialist_all_get:host_service.host + '/ema/cardiology/specialists-all',
    specialist_by_type_get:host_service.host + '/ema/cardiology/specialists-type',
    adjuvants_data_new:host_service.host + '/ema/cardiology/adjuvants',
    adjuvants_data_by_event_id_get:host_service.host + '/ema/cardiology/adjuvants',
    angiography_has_segment_new:host_service.host + '/ema/cardiology/angiographieshassegments',
    angiography_has_segment_by_event_segment_get:host_service.host + '/ema/cardiology/angiographieshassegments-by-event-segment',
    angiography_has_segment_by_event_get:host_service.host + '/ema/cardiology/angiographieshassegments-by-event',
    stent_externo_save: host_service.host + '/ema/cardiology/stents-externos',
    stent_externo_by_event_and_segment_get: host_service.host + '/ema/cardiology/stents-externos-by-event-id',
    stent_externo_delete: host_service.host + '/ema/cardiology/stents-externos',

    //access_via_byangiography_has_segment_id_get:host_service.host + '/ema/cardiology/angiographieshasaccess-has-segment-id',
    //access_via_byangiography_has_segment_id_post:host_service.host + '/ema/cardiology/angiographieshasaccess',
    //access_via_byangiography_has_segment_id_delete:host_service.host + '/ema/cardiology/angiographieshasaccess'
    access_via_byevent_id_get:host_service.host + '/ema/cardiology/eventhasaccess-has-event-id',
    access_via_byevent_id_post:host_service.host + '/ema/cardiology/eventhasaccess',
    access_via_byevent_id_delete:host_service.host + '/ema/cardiology/eventhasaccess',
    event_general_data_new:host_service.host + '/ema/cardiology/event-general-data',
    event_general_data_by_event_id:host_service.host + '/ema/cardiology/event-general-data',
    events_outcomes_new:host_service.host + '/ema/cardiology/events-outcomes',
    events_outcomes_by_patient_id:host_service.host + '/ema/cardiology/events-outcomes',
    events_outcomes_by_event_id:host_service.host + '/ema/cardiology/events-outcomes',
    events_procedimientos_byevent_id_get:host_service.host + '/ema/cardiology/eventshasprocedimientos-has-event-id',
    events_procedimientos_byevent_id_post:host_service.host + '/ema/cardiology/eventshasprocedimientos',
    events_procedimientos_byevent_id_delete:host_service.host + '/ema/cardiology/eventshasprocedimientos',
    events_procedimientos_data_by_id_get:host_service.host + '/ema/cardiology/eventshasprocedimientos',
    events_motivos_byevent_id_get:host_service.host + '/ema/cardiology/eventshasmotivos-has-event-id',
    events_motivos_byevent_id_post:host_service.host + '/ema/cardiology/eventshasmotivos',
    events_motivos_byevent_id_delete:host_service.host + '/ema/cardiology/eventshasmotivos',
    events_motivos_data_by_id_get:host_service.host + '/ema/cardiology/eventshasmotivos',

    events_scales_byevent_id_get:host_service.host + '/ema/cardiology/events-scales',
    events_scales_byevent_id_post:host_service.host + '/ema/cardiology/events-scales',
    events_scales_byevent_id_delete:host_service.host + '/ema/cardiology/events-scales',
    events_scales_data_by_id_get:host_service.host + '/ema/cardiology/events-scales',

    countries_by_lang_id_get:host_service.host + '/ema/cardiology/countries',
    states_get:host_service.host +  '/ema/cardiology/states-country',
    cities_get:host_service.host + '/ema/cardiology/cities-state',
    stents_history_get:host_service.host + '/ema/cardiology/stents-history',
    guia_intracoronaria_get:host_service.host + '/ema/cardiology/angioplastias-has-guia-intracoronarias',
    guia_intracoronaria_delete:host_service.host + '/ema/cardiology/angioplastias-has-guia-intracoronarias',
    guia_intracoronaria_save:host_service.host + '/ema/cardiology/angioplastia-has-guia-intracoronaria',
    general_medicine_byevent_id_get:host_service.host + '/ema/cardiology/generalhasmedicine-has-event-id',
    general_medicine_byevent_id_post:host_service.host + '/ema/cardiology/generalhasmedicine',
    general_medicine_byevent_id_delete:host_service.host + '/ema/cardiology/generalhasmedicine',
    general_medicine_data_by_id_get:host_service.host + '/ema/cardiology/generalhasmedicine',

    balon_semicompliante_save:host_service.host + '/ema/cardiology/angioplastia-has-balon-semicompliante',
    balon_semicompliante_get_all:host_service.host + '/ema/cardiology/angioplastia-has-balon-semicompliante',
    balon_semicompliante_by_event_segment_get:host_service.host + '/ema/cardiology/angioplastias-has-balon-semicompliantes',
    balon_semicompliante_delete:host_service.host + '/ema/cardiology/angioplastias-has-balon-semicompliante',
    balon_nc_save: host_service.host + '/ema/cardiology/angioplastia-has-balon-nc',
    balon_nc_get_all: host_service.host + '/ema/cardiology/angioplastia-has-balon-nc-all',
    balon_nc_by_event_segment_get: host_service.host + '/ema/cardiology/angioplastias-has-balon-nc',
    balon_nc_delete: host_service.host + '/ema/cardiology/angioplastias-has-balon-nc',

    angiography_header_save:host_service.host + '/ema/cardiology/angiographies',
    angiography_header_copy:host_service.host + '/ema/cardiology/angiographieshassegments/copy',
    angiography_header_by_patient_id_get:host_service.host + '/ema/cardiology/angiographies-patient-id',
    angiography_header_all_get:host_service.host + '/ema/cardiology/angiographies-all',
    angiography_header_by_patient_id_event_id_get:host_service.host + '/ema/cardiology/angiographies-patient-id-event-id',
    contents_get:host_service.host + '/ema/cardiology/content',
    general_guias_curvas_byevent_id_get:host_service.host + '/ema/cardiology/generalhascateterguiascurvas-has-event-id',
    general_guias_curvas_byevent_id_post:host_service.host + '/ema/cardiology/generalhascateterguiascurvas',
    general_guias_curvas_byevent_id_delete:host_service.host + '/ema/cardiology/generalhascateterguiascurvas',
    general_guias_curvas_data_by_id_get:host_service.host + '/ema/cardiology/generalhascateterguiascurvas',
    general_cateter_diagnostico_byevent_id_get:host_service.host + '/ema/cardiology/generalhascateterdiagnostico-has-event-id',
    general_cateter_diagnostico_byevent_id_post:host_service.host + '/ema/cardiology/generalhascateterdiagnostico',
    general_cateter_diagnostico_byevent_id_delete:host_service.host + '/ema/cardiology/generalhascateterdiagnostico',
    general_cateter_diagnostico_data_by_id_get:host_service.host + '/ema/cardiology/generalhascateterdiagnostico',
    general_ultrasonidos_byevent_id_get:host_service.host + '/ema/cardiology/generalhasultrasonidos-has-event-id',
    general_ultrasonidos_byevent_id_post:host_service.host + '/ema/cardiology/generalhasultrasonidos',
    general_ultrasonidos_byevent_id_delete:host_service.host + '/ema/cardiology/generalhasultrasonidos',
    general_ultrasonidos_data_by_id_get:host_service.host + '/ema/cardiology/generalhasultrasonidos',
    angioplasty_header_by_event_id_get:host_service.host + '/ema/cardiology/angioplastias-event',
    angioplasty_header_save:host_service.host + '/ema/cardiology/angioplastia',
    angioplasty_header_delete:host_service.host + '/ema/cardiology/angioplastia',
    stent_save: host_service.host + '/ema/cardiology/stents',
    stent_by_event_and_segment_get: host_service.host + '/ema/cardiology/stents-by-event-id',
    stent_by_event_and_segment_get_copy: host_service.host + '/ema/cardiology/stents-by-event-id-for-copy',
    stent_delete: host_service.host + '/ema/cardiology/stents',
    perdida_material_save: host_service.host + '/ema/cardiology/angioplastia-has-perdida-materiales',
    perdida_material_by_event_segment_get: host_service.host + '/ema/cardiology/angioplastias-has-perdida-materiales',
    perdida_material_delete: host_service.host + '/ema/cardiology/angioplastias-has-perdida-materiales',
    angioplastia_has_segmentos_save: host_service.host + '/ema/cardiology/angioplastia-has-segmentos',
    angioplastia_has_segmentos_get: host_service.host + '/ema/cardiology/angioplastia-has-segmentos',
    dcm_files_get: host_service.host + '/ema/cardiology/file-download',
    dcm_files_new: host_service.host + '/ema/cardiology/file-upload',
    dcm_files_delete: host_service.host + '/ema/cardiology/file',
    dcm_files_data_new: host_service.host + '/ema/cardiology/upload-files-data',
    dcm_files_data_get: host_service.host + '/ema/cardiology/upload-files-data',
    dcm_files_data_filters_get: host_service.host + '/ema/cardiology/upload-files-data-filters',
    reports_get: host_service.host + '/ema/cardiology/reports-by-event',
    report_save: host_service.host + '/ema/cardiology/report',
    clinical_results_get: host_service.host + '/ema/cardiology/clinical-results',
    clinical_results_save: host_service.host + '/ema/cardiology/clinical-results',
    pages_options_get: host_service.host + '/ema/cardiology/security/page-options',
    pages_options_permissions_get: host_service.host + '/ema/cardiology/security/page-options-enabled',
    pages_data_get: host_service.host + '/ema/cardiology/security/page-data',
    get_file_system_data:host_service.host + '/ema/cardiology/files-system-data',
    datos_generales_save_guias_presion:host_service.host+'/ema/cardiology/guias-presion',
    datos_generales_get_guias_presion:host_service.host+'/ema/cardiology/guias-presion',

    user_profile_data_get:host_service.host+'/ema/cardiology/security/user',
    user_profile_data_save:host_service.host+'/ema/cardiology/security/user',



  }

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
