import { emaUtils } from '../AIONSystemsComponentsV1_0_0/ema-utils/documents-utils';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment.dev';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/shareReplay';

export class userdata{
  username:string;
  password:string;
}
@Injectable()
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private router: Router) { }
  user_json= {} as userdata;

  login(username: string, password: string) {
  // debugger;

    sessionStorage.setItem('access_token' , 'temporal');
    const params = new HttpParams({
      fromObject: {
        user_email: username,
        user_password: password,
        language_id: '1'
      }
    });
    this.user_json.username=username;
    this.user_json.password=password;
    return this.httpClient.post(environment.api.login,
    this.user_json,
    {
      headers: new HttpHeaders({
           'Content-Type':  'application/json',
         })
    }).do((authResult: any) => {
      localStorage.setItem('user',authResult.data.user);
      localStorage.setItem('user_id', authResult.data.user_id);
      localStorage.setItem('role_id', authResult.data.role_data.role_id);
      //"Bearer "
      sessionStorage.setItem('access_token', authResult.data.token);
      localStorage.setItem('token_id', authResult.data.token_id);

      sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
      localStorage.setItem('session_active', 'active');
      localStorage.setItem('user_id_profile', '1');


      /*localStorage.setItem('user', authResult.data.user_data.user_detail.name /*
      + ' '
      + authResult.data.user_data.user_detail.middle_name
      + ' '
      + authResult.data.user_data.user_detail.last_name);
      localStorage.setItem('user_role', authResult.data.user_data.user_detail.role);*/
      /////branch_office_name SE TOMA PARA MOSTRAR EN EL PERFIL DEL USUARIO -  para el cliente se está tomando el campo commercial_name de la BD

     // ////////////console.log(username + ">>>>>>>>> ");
    })
    .shareReplay();
  }

  refreshToken(access_token: string, refresh_token: string) {
   // ////////////console.log("refreshToken session.....");
    //session_active == 'null' ? "nulo" : 0
    if (localStorage.getItem("session_active") == "inactive" || localStorage.getItem("session_active") == 'null'){
      this.logout();
     // ////////////console.log("Session cerrada en otra pestaña");
      return;
    }else if (localStorage.getItem("session_active") == "active"){
      //////////////console.log("Session abierta...");
    }
    const params = new HttpParams({
      fromObject: {
        token: access_token,
        refresh_token: refresh_token
      }
    });
    const data = {} as tokenRefreshModel;
    data.token = access_token;
    data.refresh_token = refresh_token;
    data.user_name = localStorage.getItem("user");
    data.token_id = Number(localStorage.getItem("token_id"));
    data.user_id=Number(localStorage.getItem("user_id"));
    return this.httpClient.post(environment.api.refreshToken,
      data,
    {
      headers: new HttpHeaders({
           'Content-Type':  'application/json',
         })
    }).do((authResult: any) => {

      sessionStorage.setItem('access_token', authResult.data.token);
      sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
      sessionStorage.setItem('token_id', authResult.data.token_id);
    })
    .shareReplay();
  }
  obtenerMenu() {
    let rol_id = localStorage.getItem('role_id');
    let user_id = localStorage.getItem('user_id');
    return this.httpClient.get(environment.api.menu+ '/'+rol_id+'/'+user_id+'/1'
    ).do((menuResp: any) => {
      //////////////console.log('Todo el menu:', menuResp);
      let accessURL = [];
      let defaultURL = '';
      //filtrar sidebar
      let menuTemp = menuResp.data.filter(item => {

      item.submenu = item.submenu.filter(subitem => {

        accessURL.push(subitem.path);
        //////////////console.log("MENU--------------------");
     //   ////////////console.log(subitem);
         if(subitem.is_default_page == 1) {
          //////////////console.log("Default page--------------------");
          localStorage.setItem('menu', item.menu_id);
          localStorage.setItem('submenu', subitem.menu_id);
           defaultURL = subitem.path;
         }
         return (subitem.is_in_menu == 1);
        });
       return (item.is_in_menu == 1);
      });
      //mapear sidebar
      menuTemp.map(item =>{
        item.title = item.module;
        item.class = item.class_;
       // ////////////console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>> " +item.class);
        //////////////console.log(item.submenu);
        item.submenu.map(subitem => {
          //si es document se agregan parametros en url
          /*if( (subitem.path === '/document') || (subitem.path === '/customer-document' ) ) {

            subitem.path = subitem.path + '/' + item.menu_id + '/' + subitem.menu_id;
          //  ////////////console.log("Paginas que tiene acceso: " + subitem.path);
            accessURL.push(subitem.path);

          }else if ((subitem.path === '/notification')){
            //////////////console.log("Paginas que tiene acceso: " + subitem.path);
            subitem.path = subitem.path;
            accessURL.push(subitem.path);
          }*/
         // //////////console.log("subitem " +subitem.class_);
         // //////////console.log(subitem);
          subitem.class = subitem.class_==null? "l":subitem.class_;
          subitem.submenu = [];
          return subitem;
        });
        return item;
      });
      let menu = {
        data: menuTemp,
        accessURL : accessURL,
        defaultURL: defaultURL
      };
      //////////////console.log('Filtro menu sidebar:', menuTemp);
      //////////////console.log('---> Url access:', accessURL);
      //////////////console.log(JSON.stringify(menu));
      sessionStorage.setItem('menu', JSON.stringify(menu));
    });
  }

  logout() {
    //////////////console.log("user id:: " + localStorage.getItem('customer'));
     // remove user from local storage to log user out
    const data= {} as tokenRefreshModel;
    data.token=sessionStorage.getItem("access_token");
    data.refresh_token=sessionStorage.getItem("refresh_token");
    data.user_name=localStorage.getItem("user");
    data.user_id=Number(localStorage.getItem("user_id"));
    data.token_id=Number(localStorage.getItem("token_id"));

    return this.httpClient.post(environment.api.logout,
              data,
              {
               headers: new HttpHeaders({
                'Content-Type':  'application/json',
              })}).do((data) => {
                  sessionStorage.removeItem('access_token');
                  sessionStorage.removeItem('refresh_token');
                  sessionStorage.removeItem('menu');
                  localStorage.removeItem('user');
                  //Se elimina menu
                  localStorage.removeItem('titleMenu');
                  localStorage.clear();
                  sessionStorage.clear();
                  this.router.navigate(['/login']);
              } );
  }
  cleanCache(){
    //////////////console.log("User is logged - " + localStorage.getItem("'user"));
    localStorage.setItem("session_active", "inactive");

    localStorage.removeItem('username') ;
    localStorage.removeItem('passwor') ;

    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('menu');

    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    localStorage.removeItem('submenu');
    //Se elimina menu
    localStorage.removeItem('titleMenu');
    localStorage.clear();
    sessionStorage.clear();
  }

  getUserDataByName(user_name) {
    //////console.log("--- En servicio ---");
    let r =  this.httpClient
    .get(environment.api.user_profile_name_get+"/"+user_name
    ).do((data: any) => {
      //////console.log("Data............");
      //////console.log(data);
      //data = data;
    }).shareReplay();
    //////console.log("Resp............");
    //////console.log(r);
    return r;
  }

  getUserDataByNameV1(user_name) {
    const headers = null;
    return this.httpClient.get<any>(environment.api.user_profile_name_get+"/"+user_name,
    { 'headers': headers }
    ).do((resp: any) => {
      //////console.log('Response :', resp);
    });

  }


}
export class tokenRefreshModel{
  token:string;
  refresh_token:string;
  user_name:string;
  user_id:number;
  token_id:number;
}
