import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';


import { LoginV10Component } from './login-v10/login-v10.component';
import { AccesoDenegadoComponent } from './acceso-denegado/acceso-denegado.component';
import { AuthGuard } from './auth.guard';
//
export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren:'./CardiologyComponentV10/home/home/home.module#HomeModule',

        canActivate: [AuthGuard]
      },
      {
        path: 'user-profile',
        loadChildren:'./CardiologyComponentV10/user-profile/user-profile/user-profile.module#UserProfileModule',

        canActivate: [AuthGuard]
      },
      {
        path: 'spinner',
        loadChildren:'./CardiologyComponentV10/home/home/home.module#HomeModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'angiography',
        loadChildren: './CardiologyComponentV10/angiography/angiography/angiography.module#AngiographyModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'angioplasty',
        loadChildren: './CardiologyComponentV10/angioplasty/angioplasty/angioplasty.module#AngioplastyModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'patients-manager',
        loadChildren: './CardiologyComponentV10/patient/patient/patient.module#PatientModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'patients',
        loadChildren: './CardiologyComponentV10/patient/patient-list/patient-list/patient-list.module#PatientListModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'verification-patient',
        loadChildren: './CardiologyComponentV10/verification-patient/verification-patient/verification-patient.module#VerificationPatientModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        loadChildren: './CardiologyComponentV10/report/report/report.module#ReportModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'search',
        loadChildren: './CardiologyComponentV10/search/search/search.module#SearchModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'outcomes',
        loadChildren: './CardiologyComponentV10/outcomes/outcomes/outcomes.module#OutcomesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'med-studies',
        loadChildren: './CardiologyComponentV10/medical-studies/medical-studies/medical-studies.module#MedicalStudiesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'configuration',
        loadChildren: './CardiologyComponentV10/configuration/configuration/configuration.module#ConfigurationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'events-list',
        loadChildren: './CardiologyComponentV10/event/event-list/event-list/event-list.module#EventListModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'events',
        loadChildren: './CardiologyComponentV10/event/event/event.module#EventModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'general-data',
        loadChildren: './CardiologyComponentV10/general-data-for-process/general-data-for-process/general-data-for-process.module#GeneralDataForProcessModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'dcm-files',
        loadChildren: './CardiologyComponentV10/dcm-files/dcm-files/dcm-files.module#DcmFilesModule',
        canActivate: [AuthGuard]
       }
      ,
      {
        path: 'resume',
        loadChildren: './CardiologyComponentV10/resume/resume/resume.module#ResumeModule',
        canActivate: [AuthGuard]
      } ,
      {
        path: 'scales',
        loadChildren: './CardiologyComponentV10/scales/scales/scales.module#ScalesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'results',
        loadChildren: './CardiologyComponentV10/results/results/results.module#ResultsModule',
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'login',
    component: LoginV10Component,
  },
  {
    path: 'acceso-denegado',
    component: AccesoDenegadoComponent
  }
];
