import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';


import { RouterModule, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment.dev';
import { emaUtils } from '../AIONSystemsComponentsV1_0_0/ema-utils/documents-utils';
import { pagesModel } from '../CardiologyComponentV10/models/pagesOption';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfileService } from '../CardiologyComponentV10/services/user-profile.service';
import { userModelV1 } from '../model/userModel';


@Component({
  selector: 'app-login-v10',
  templateUrl: './login-v10.component.html',
  styleUrls: [ './login-v10.component.scss'],
  providers: [AuthService, emaUtils,UserProfileService,
    NgbActiveModal]
})
export class LoginV10Component implements OnInit {
  msg = '';
  staticAlertClosedLogin = true;
  alertLogin:IAlert;
  development_indicator:String;
  hidden_denvironment_text:boolean;
  pages_: pagesModel[]=[];

  @ViewChild("ChangePasswordNotificationModal", { static: true })
  change_password_modal: ElementRef;
  constructor(
    private tools: emaUtils,
    private authService: AuthService,
    private routes: Router,
    private modalService: NgbModal) {
////console.log("Ambiente:", environment.ambiente +  " " +  environment.descrpcion_ambiente);

    if (environment.ambiente == "DES" || environment.ambiente == "QAS"){
      this.development_indicator = environment.descrpcion_ambiente;
      this.hidden_denvironment_text = false;
    }else if (environment.production){
      this.development_indicator = "";
      this.hidden_denvironment_text = true;
    }
    this.alertLogin = {
      id: 1,
      type: 'danger',
      message: 'Se requiere seleccionar todos los campos..'
    };
  }

  loginform = true;
  recoverform = false;
  ennableInputs = false;
  ennableSpinner = true;
  user_data={} as userModelV1;
  user_name:string;
  getUserData(){
    this.getUserByID(this.user_name);
  }
  getUserByID(user_name:string){
    //console.log(user_name);
    this.authService.getUserDataByNameV1(user_name).subscribe((res:any)=>{

      //if (res.api_code==200){
        this.user_data=res.data;
      //}
    },(err: any) => {
      //console.log("Error al consultar  -->",err);
    }
    );
  }
  openChangePassword(){
    this.modalService.open(this.change_password_modal);
  }
  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }
  closeResult: string;
  cancel() {
    this.modalService.dismissAll();
  }
  openModal(content) {
    this.modalService
      .open(content, {
        size: "lg",
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit() {

    let session_active = localStorage.getItem("session_active");

    if ( session_active == "inactive" ||  session_active == null){

      this.routes.navigate(['/login']);
    }else{

      this.check(localStorage.getItem("username_enc"), localStorage.getItem("psw_enc"));
    }
  }
  checkLogginActive(){
    let session_active = localStorage.getItem("session_active");
    if ( session_active == "active" ){

      this.check(localStorage.getItem("username_enc"), localStorage.getItem("psw_enc"));
    }
  }
  check(uname: string, p: string) {

    this.tools.cleanCache();
    localStorage.setItem("username_enc", uname);
    localStorage.setItem("psw_enc", p);
    //Validar
   // debugger;
    this.ennableInputs = true;
    this.ennableSpinner = false;
    this.staticAlertClosedLogin = true;
    if(uname.length > 0 && p.length > 0) {

          this.authService.login(uname, p).subscribe( (res: any) => {
            this.msg = "";
            if (res.code  != '200') {
              this.staticAlertClosedLogin = false;
              this.alertLogin.type = 'danger';
              this.alertLogin.message = 'Error de login.';
              this.resetLogin();
              this.ennableSpinner = true;
              this.ennableInputs = false;
            } else {
              let i = '';
              this.authService.obtenerMenu().subscribe((respMenu: any) => {
                //buscar url default

                for(let itemMenu of respMenu.data){
                  for(let itemSubMenu of itemMenu.submenu){
                    let c_= {} as pagesModel;
                    c_.page_id=itemSubMenu.menu_id;
                    c_.path = itemSubMenu.path;
                    if(itemSubMenu.is_default_page == 1){
                      i = itemSubMenu.path;
                      //////////console.log("Se encontro default");
                    }
                  }
                }
                // ////console.log("name");
                // ////console.log(res.data.name);
                localStorage.setItem("user_name_prof", res.data.data.name);
                /*if( i.length == 0) {
                  //////////console.log('no se encontro default, se asigna por perfil');
                  if(res.data.user_data.is_employee == 1) {
                    i = '/reportes';
                  } else if (res.data.user_data.is_employee == 0 ) {
                    ///GET FOLDER ACCEPT DATA
                    //////////console.log("Respuesta ----- ****");

                    i = 'notification'//'/customer-document';
                  } else {
                    i = '/login';
                  }
                }*/
                //////////console.log('URL default:', i);
                //////////console.log("routing to "  + i);
                this.routes.navigate([i]);
              },(err: any) => {
                ////////console.log("Error al consultar el menu -->",err);
              }
              );
              ////////console.log("Iniciando sistema...");
              //let  i = "/home";
              this.routes.navigate([i]);
            }
          },
          (err: any) => {
            this.staticAlertClosedLogin = false;
            this.alertLogin.type = 'danger';
            this.alertLogin.message = 'Usuario o contraseña incorrecto.';
            this.ennableSpinner = true;
            this.ennableInputs = false;
            this.resetLogin();
          }
        );
    } else {
      this.staticAlertClosedLogin = false;
      this.alertLogin.type = 'danger';
      this.alertLogin.message = 'Se requiere usuario y contraseña.';
      this.resetLogin();
      this.ennableSpinner = true;
      this.ennableInputs = false;
    }
  }

  resetLogin(){
    setTimeout(() => {this.staticAlertClosedLogin = true}, 1500);
  }
}


export interface IAlert {
  id: number;
  type: string;
  message: string;
}
